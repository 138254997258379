<template lang="">
    <div class="atlas-map-wrapper">
        <atlas-map ref="atlasMap"/>
    </div>
</template>
<script>
import AtlasMap from '@/modules/atlas/components/AtlasMap';
import LeftSidebar from '@/modules/atlas/components/LeftSidebar';
import RightSidebar from '@/modules/atlas/components/RightSidebar';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'AtlasPublicView',
    components: {
        AtlasMap,
    },
    computed: {
    },
    methods: {
    },
    async created() {
    },
}
</script>
<style lang="scss">
    .atlas-map-wrapper {
        height: 100%;
        margin: 0 -12px;
    }
</style>
